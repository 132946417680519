
import { useReactPWAInstall } from '@amtpub/react-pwa-install';
// import {  Col, Container, Row, Button } from 'react-bootstrap';
import './Vcard.css';
import { QrCode as QrCodeIcon, Share, Bell } from 'react-bootstrap-icons'
import {React, useState} from 'react';
import {  Container, Nav, Offcanvas, Modal, Row, Col } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import { WhatsappShareButton, EmailShareButton, WhatsappIcon, EmailIcon, FacebookMessengerShareButton, FacebookMessengerIcon } from 'react-share';

import { platforms, getPlatform } from "./pwa-install/Platforms";
import IOSShareIcon from "./pwa-install/IOSShareIcon";
import IOSHomeScreenIcon from "./pwa-install/IOSHomeScreenIcon";

import { FireFoxA2HSIcon, MenuIcon, OperaA2HSIcon } from "./pwa-install/Icons";

const platform = getPlatform();

const isiOS13AndUp = /OS (13|14)/.test(window.navigator.userAgent);

function logger(message) {
    if (true) {
      console.log(message);
    }
  }
  
export function isInstalled() {
    if (window.navigator.standalone === true || window.matchMedia("(display-mode: standalone)").matches) {
      logger("isInstalled: true. Already in standalone mode");
      return true;
    }
    logger("isInstalled: false.");
    return false;
  }

export function supported() {
    if (platform === platforms.NATIVE) {
      logger("supported: true - native platform 1");
      return true;
    }
    if (platform !== platforms.NATIVE && platform !== platforms.OTHER) {
      logger("supported: true - manual support 2");
      return true;
    }
    logger("supported: false");
    return false;
  }


function Vcard(props) {
        const {pwaInstall, supported2, isInstalled2} = useReactPWAInstall();
        const [showShare, setShowShare] = useState(false);
        const [showQr, setShowQr] = useState(false);
  
        const handleCloseShare = () => setShowShare(false);
        const handleShowShare = () => setShowShare(true);
        const handleCloseQr = () => setShowQr(false);
        const handleShowQr = () => setShowQr(true);

        const [showInstall, setShowInstall] = useState(false);

        const handleCloseInstall = () => setShowInstall(false);
        const handleShowInstall = () => setShowInstall(true);


        const handleClick = () => {
            
             platform !== platforms.IDEVICE && pwaInstall({
                title: "eWizytówka",
                // logo: myLogo,
                features: (
                    <ul>
                    <li>Posiada certyfikat bezpieczeństwa.</li>
                    <li>Szybko się ładuje i działa również w trybie offline.</li>
                    <li>Możesz szybko i bez ograniczeń udostępniać dowolnej liczbie osób.</li>
                    </ul>
                ),
                featuresTitle: "Twoja wizytówka w wygodnej aplikacji:",
                description: "Zainstaluj eWizytówkę i przekonaj się sam jakie to proste i wygodne narzędzie do komunikacji z klientami lub pracownikami",
                descritpionTitle: " ",
                instructionActionInstall: "Zainstaluj",
                instructionActionCancel: "Anuluj",
                instructionActionOK: "OK",
                })
                // .then(() => alert("App installed successfully or instructions for install shown"))
                .catch(() => alert("Szkoda, że nie zdecydowałe/aś się na pobranie aplikacji"));
                
                platform === platforms.IDEVICE && handleShowInstall();
                
        };
    return (
        <>
              <div className="wizytowka">      
                <img src="wizKsiaWisniewska.svg" className="img-fluid" alt="Kasia Wiśniewska"></img>        
                {supported() && !isInstalled() && (
                    <>
                    <div className="tlo img-fluid">
                        <img className="img-fluid" id="icon" src="192.png"></img>    
                        <btn className="button" onClick={handleClick}>pobierz eWizytówkę >></btn>
                    </div>
                    <DialogActionWithInstructions2 showInstall={showInstall} handleCloseInstall={handleCloseInstall}/>
                    </>
                 )} 
                

                <div className="button2 img-fluid">
                {/* <div>     */}
                <btn className="button3" onClick={handleShowQr}><img src="./qrcode1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="50" width="auto"></img></btn>
                {/* <div className="ActionName">QR</div> */}
                {/* </div>
                <div> */}
                <btn className="button3" onClick={handleShowShare}><img src="./share1.svg" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="inline-block" height="50" width="auto"></img></btn>
                {/* <div className="ActionName">Udostępnij</div> */}
                {/* </div>} */}
                </div>
              </div>
              <Offcanvas show={showShare} onHide={handleCloseShare} placement='bottom' className="bg-light text-dark">
            <Container>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Udostępnij eWizytówkę:</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
              <Container className="d-flex justify-content-center">
                <Row>
                    <Col className="mx-1">
                <WhatsappShareButton url='https://osobista.ewizytowka.app' body='Przesyłam Ci eWizytówkę:'>
                <WhatsappIcon size='40' borderRadius={5}/>
                </WhatsappShareButton>
                </Col>
                <Col className="mx-1">
                <EmailShareButton subject='eWizytówka' url='https://osobista.ewizytowka.app' body='Przesyłam Ci eWizytówkę:'>
                    <EmailIcon size='40' borderRadius={5} />
                </EmailShareButton>
                </Col>
                <Col className="mx-1">
                <FacebookMessengerShareButton subject='eWizytówka' url='https://osobista.ewizytowka.app' body='Przesyłam Ci eWizytówkę:'>
                    <FacebookMessengerIcon size='40' borderRadius={5} />
                </FacebookMessengerShareButton>
                </Col>
                </Row>
            </Container>
          </Offcanvas.Body>
          </Container>
        </Offcanvas>
        <Modal show={showQr} onHide={handleCloseQr} centered size='lg'>
            <Modal.Header closeButton>
            <Modal.Title>Zeskanuj kod QR</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Container className="d-flex justify-content-center"><QRCode value="https://osobista.ewizytowka.app" size={250}/></Container>
            </Modal.Body>
        </Modal>
        </>
    )

}

function DialogActionWithInstructions2(props) {
    return (
        <Modal show={props.showInstall} onHide={props.handleCloseInstall} size='lg' animation={true} centered>
            <Modal.Header>
            <Modal.Title>Zainstaluj aplikację:</Modal.Title>
            
            </Modal.Header>
            <Modal.Body >
                <Container >
                {/* <Row className="m-2"> 
                    <Col className="font-weight-bold">
                        
                    </Col>
                    </Row> */}
                    <Row className="m-2"> 
                    <Col>
                        1. Naciśnij <IOSShareIcon className="pwaPromptShareIcon" modern={isiOS13AndUp} /> na dole ekranu.
                    </Col>
                    </Row>
                    <Row className="m-2">
                        <Col>
                        2. Naciśnij <IOSHomeScreenIcon className={"pwaPromptHomeIcon"} modern={isiOS13AndUp} /> i dodaj do ekranu początkowego.
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
}

export default Vcard;