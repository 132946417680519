import React from 'react';
import './App.css';
import {  Col, Container, Row, Button } from 'react-bootstrap';

import CardHeader from './components/CardHeader';
import CardFooter from './components/CardFooter';
import Picture from './components/Picture';
import Name from './components/Name';
import Vcard from './components/Vcard';
import PrimaryActions from './components/PrimaryActions';
import SecondaryActions from './components/SecondaryActions';

function App() {
  return (
    <>
    <div className="App">
      <CardHeader/>   
      <main className="App-main">
        <Container fluid>
          <Row className="mt-4 mb-2">
            <Col>
              <Picture img="256.png" alt="Kasia Wiśniewska"/>
            </Col>

          </Row>
          <Row className="mb-4">
            <Col>
              <Name name="Kasia Wiśniewska" subtitle="" />
            </Col>
          </Row>
          <Row className="mb-0">
            <Col>
              <PrimaryActions/>
            </Col>
          </Row>
          <Row className="pb-2 app-bg-part">
            <Col>
                  <Vcard/>
            </Col>
          </Row>
        </Container>
        <SecondaryActions/>
      </main>      
      <CardFooter/>
    </div>
    
  </>
  );
}

export default App;
